import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { LCArticleDetail } from '@shared/components';
import { useCallback, useEffect, useState } from 'react';

const LCArticlePreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>(null);

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'learning_center_content_page',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;

    const result = response[0][0];

    setLivePreviewData({
      ...result,
      image: result.featured_image.image,
    });
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return !!livePreviewData && <LCArticleDetail {...livePreviewData} />;
};

export default LCArticlePreviewPage;
